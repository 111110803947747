import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  svgHover: {
    fill: theme.palette.foreground.default,
    "&:hover": {
      fill: theme.palette.primary.main,
    },
    transition: "all 0.5s ease",
  },
}));

export const Logo = () => {
  const classes = useStyles();

  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="100%"
      viewBox="0 0 1000 1000"
      enable-background="new 0 0 1000 1000"
    >
      <path
        fill="#191919"
        opacity="1.000000"
        stroke="none"
        d="
M564.000000,1001.000000 
	C376.000000,1001.000000 188.500000,1001.000000 1.000000,1001.000000 
	C1.000000,667.666687 1.000000,334.333344 1.000000,1.000000 
	C334.333344,1.000000 667.666687,1.000000 1001.000000,1.000000 
	C1001.000000,334.333344 1001.000000,667.666687 1001.000000,1001.000000 
	C855.500000,1001.000000 710.000000,1001.000000 564.000000,1001.000000 
M652.002136,317.225037 
	C638.463562,292.715668 624.924988,268.206268 611.032104,243.055511 
	C606.966614,253.057770 603.731506,261.778198 599.857666,270.205017 
	C598.098877,274.030945 598.236633,276.794159 600.623352,280.247620 
	C609.230591,292.701904 617.539429,305.362457 625.029663,317.992371 
	C613.454895,317.994659 601.864868,318.355865 590.311096,317.864899 
	C583.354919,317.569305 577.834900,318.121887 576.826660,326.501007 
	C576.799927,326.723389 576.368835,326.897186 576.009338,326.137207 
	C576.006226,312.471252 575.923767,298.804291 576.091492,285.140442 
	C576.114807,283.239990 576.941162,281.016418 578.122620,279.513977 
	C592.297302,261.488586 606.835083,243.743393 620.741516,225.515717 
	C624.679260,220.354370 628.295776,218.612045 634.075256,221.532928 
	C635.036865,222.018890 636.332825,222.031113 637.459473,221.973267 
	C647.287598,221.468628 655.432922,217.941803 660.032104,208.664398 
	C664.795227,199.056412 663.459717,189.993652 657.169495,181.394958 
	C660.218262,177.289749 663.352051,173.070114 667.117371,168.000000 
	C663.662720,168.000000 661.836121,168.000000 660.009521,168.000000 
	C579.844360,168.000000 499.678589,167.835434 419.514557,168.139908 
	C403.628571,168.200241 390.569794,163.371399 380.022278,151.533569 
	C366.934082,136.844223 364.629028,116.389389 373.252258,99.633659 
	C382.388214,81.881531 398.063812,73.861443 417.609833,73.053581 
	C425.068329,72.745316 432.608490,66.013550 432.126953,56.395008 
	C431.726715,48.401058 425.406708,41.182232 417.322296,40.896229 
	C402.123779,40.358528 388.103607,43.962894 375.031891,51.491417 
	C358.219574,61.174274 347.011658,75.506485 339.930023,93.453865 
	C335.644073,104.316055 337.667358,115.528275 336.951141,126.600914 
	C336.050446,140.524612 340.740387,152.752716 348.064636,164.099396 
	C356.918365,177.815567 368.718994,188.153275 383.960327,194.548660 
	C395.741943,199.492325 407.915833,201.571732 420.798523,200.962051 
	C438.198853,246.570694 455.541809,292.028992 472.970245,337.711304 
	C450.596619,337.711304 428.405914,337.711304 406.000366,337.711304 
	C406.000366,319.778687 405.951965,302.133545 406.105652,284.490143 
	C406.116272,283.269592 407.366425,281.661194 408.492126,280.914856 
	C414.930511,276.646271 421.311829,272.219391 428.114685,268.602631 
	C431.622101,266.737885 431.295074,264.263885 430.853699,261.637970 
	C429.381317,252.877609 427.614685,244.167114 426.006836,235.429031 
	C424.507324,227.279602 423.066650,219.119370 421.586334,210.887482 
	C420.736023,211.165436 420.306152,211.168716 420.141754,211.379272 
	C409.547913,224.946350 398.913208,238.482468 388.441803,252.143677 
	C385.540588,255.928665 382.675903,259.403290 382.799286,265.123230 
	C383.287811,287.778931 383.000000,310.451385 383.000000,333.117615 
	C383.000000,334.703796 383.000000,336.290009 383.000000,338.000000 
	C353.516602,338.000000 324.692200,338.191040 295.872528,337.903900 
	C284.690979,337.792511 274.364655,340.677856 264.502228,345.258575 
	C247.007126,353.384338 233.424500,366.093750 224.100754,382.945160 
	C217.624954,394.649323 213.878281,407.140625 213.895706,420.897430 
	C214.068039,557.061340 214.050278,693.225586 213.929214,829.389587 
	C213.915787,844.493225 218.583252,857.971985 226.252197,870.578674 
	C235.014374,884.982483 247.268356,895.884644 262.164276,903.533569 
	C274.867981,910.056885 288.778412,912.660400 302.953674,912.912720 
	C323.279999,913.274414 343.616943,913.038818 363.738312,913.764526 
	C357.288666,931.276367 350.839020,948.788208 344.391724,966.293701 
	C345.576233,966.728882 345.885254,966.941650 346.194458,966.941956 
	C375.834381,966.971497 405.474426,967.020325 435.113617,966.880127 
	C436.399200,966.874023 438.340881,965.387573 438.842163,964.136169 
	C440.957001,958.856628 442.676178,953.416138 444.476013,948.013245 
	C448.357666,936.361023 452.199829,924.695618 456.982819,913.001953 
	C493.971405,913.018066 530.959961,913.034180 567.993530,913.927673 
	C567.662292,918.845703 567.421021,923.771851 566.977051,928.679688 
	C566.416809,934.872803 565.424377,941.037415 565.110168,947.239197 
	C564.781677,953.722473 565.036804,960.235352 565.036804,966.659729 
	C595.693848,966.659729 626.739746,966.659729 657.480957,966.659729 
	C658.686646,948.553162 659.868530,930.805237 661.977112,913.004028 
	C679.802246,912.338501 697.754700,912.771851 715.419800,910.725281 
	C736.801331,908.248108 754.070312,897.195007 767.985291,880.637146 
	C782.293945,863.610840 788.862549,843.816772 788.899353,822.039551 
	C789.107483,698.893433 789.024780,575.746765 788.956604,452.600342 
	C788.950623,441.850159 788.165588,431.102203 788.041870,420.349792 
	C787.879639,406.250671 783.762878,393.339294 776.706116,381.320740 
	C765.692383,362.562897 749.832947,349.631470 729.461975,341.919983 
	C718.568604,337.796265 707.355286,337.946320 696.009460,337.005524 
	C696.411377,330.732208 696.813354,324.458862 697.229736,317.960388 
	C681.521606,317.960388 666.800293,317.960388 652.002136,317.225037 
z"
      />
      <path
        fill="#f7dcad        "
        opacity="1.000000"
        stroke="none"
        d="
M576.126221,327.093628 
	C576.368835,326.897186 576.799927,326.723389 576.826660,326.501007 
	C577.834900,318.121887 583.354919,317.569305 590.311096,317.864899 
	C601.864868,318.355865 613.454895,317.994659 625.963623,318.022614 
	C635.291382,318.022034 643.685181,317.991211 652.078979,317.960388 
	C666.800293,317.960388 681.521606,317.960388 697.229736,317.960388 
	C696.813354,324.458862 696.411377,330.732208 695.695496,337.698914 
	C694.587891,349.249420 693.687683,360.100555 693.076538,370.967957 
	C692.925964,373.644440 693.717041,376.373932 693.863770,379.443848 
	C693.101807,386.167023 692.457092,392.518951 692.021606,398.885193 
	C691.279419,409.735260 690.673035,420.594574 690.001526,431.449524 
	C689.340332,442.137909 688.660156,452.825104 688.004578,463.513824 
	C686.995911,479.960907 686.003174,496.408936 684.999817,512.856323 
	C684.337646,523.711487 683.665833,534.565979 683.003601,545.421143 
	C682.000366,561.866272 681.003418,578.311829 680.000244,594.756958 
	C679.338074,605.612183 678.663025,616.466553 678.005249,627.321960 
	C676.998474,643.936035 676.016113,660.551514 674.994995,677.164673 
	C674.358643,687.518433 673.642944,697.867249 673.010803,708.221252 
	C671.986511,724.999023 671.014648,741.780090 669.995300,758.558167 
	C669.356079,769.079651 668.649536,779.596985 668.010132,790.118469 
	C666.990540,806.895630 665.932861,823.671021 665.028015,840.454529 
	C664.538330,849.536438 664.359802,858.634827 663.961426,867.722351 
	C663.913635,868.812866 663.366516,869.881531 662.989197,871.379517 
	C662.630493,872.740417 662.128540,873.669067 662.067627,874.625793 
	C661.342224,886.013123 660.637939,897.402344 660.062561,908.797913 
	C659.991821,910.197571 660.702026,911.636597 661.050293,913.057373 
	C659.868530,930.805237 658.686646,948.553162 657.480957,966.659729 
	C626.739746,966.659729 595.693848,966.659729 565.036804,966.659729 
	C565.036804,960.235352 564.781677,953.722473 565.110168,947.239197 
	C565.424377,941.037415 566.416809,934.872803 566.977051,928.679688 
	C567.421021,923.771851 567.662292,918.845703 568.003296,913.046997 
	C568.061157,910.680786 568.055115,909.191162 568.165955,907.710266 
	C569.083557,895.452026 570.022766,883.195435 570.975342,870.495117 
	C571.857422,858.489868 572.719666,846.927734 573.609131,834.999878 
	C571.838867,834.999878 570.031311,834.999878 568.223816,834.999878 
	C542.424133,834.999878 516.624451,834.999817 490.824768,834.999878 
	C482.827454,834.999878 482.760498,834.977783 480.343292,842.278992 
	C477.193268,851.793701 474.162659,861.347900 470.761688,871.058594 
	C465.647858,885.166565 460.852966,899.101074 456.058136,913.035583 
	C452.199829,924.695618 448.357666,936.361023 444.476013,948.013245 
	C442.676178,953.416138 440.957001,958.856628 438.842163,964.136169 
	C438.340881,965.387573 436.399200,966.874023 435.113617,966.880127 
	C405.474426,967.020325 375.834381,966.971497 346.194458,966.941956 
	C345.885254,966.941650 345.576233,966.728882 344.391724,966.293701 
	C350.839020,948.788208 357.288666,931.276367 363.996765,913.075928 
	C366.440979,906.709900 368.763824,901.080627 370.779541,895.343506 
	C373.622314,887.252258 376.237732,879.081055 379.094452,870.595581 
	C381.334686,864.486755 383.430115,858.726074 385.527039,852.965942 
	C398.894806,816.244934 412.288116,779.533325 425.624054,742.800781 
	C439.259094,705.244202 452.812195,667.657837 466.448303,630.101624 
	C481.114288,589.708923 495.822754,549.331665 510.535736,508.956055 
	C522.221313,476.888275 533.944702,444.834351 545.644653,412.771820 
	C549.742615,401.541687 553.820801,390.304321 558.169189,378.816925 
	C559.704773,375.160431 561.070312,371.788239 562.237488,368.348694 
	C566.900574,354.608368 571.501953,340.847107 576.126221,327.093628 
M551.995483,636.487244 
	C540.930420,668.498230 529.865356,700.509216 518.717285,732.760315 
	C540.404663,732.760315 561.502502,732.760315 582.482178,732.760315 
	C584.001831,714.675720 585.512756,696.841125 586.995056,679.004089 
	C588.014771,666.733154 589.037659,654.462219 589.982483,642.185364 
	C591.357849,624.313232 592.718628,606.439575 593.979797,588.559143 
	C594.756836,577.542664 595.313660,566.510803 596.015991,555.488831 
	C596.330261,550.556763 596.730286,545.629578 597.142456,540.704468 
	C597.908020,531.557434 598.711182,522.413574 599.498596,513.268372 
	C598.908264,512.948364 598.317871,512.628357 597.727539,512.308411 
	C596.471619,513.527771 594.583740,514.520935 594.054077,516.000366 
	C585.260986,540.559937 576.612976,565.171570 567.986816,589.790588 
	C562.632874,605.070496 557.369873,620.382263 551.995483,636.487244 
z"
      />
      /* Cat */
      <path
        fill="#FFFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M576.067749,326.615417 
	C571.501953,340.847107 566.900574,354.608368 562.237488,368.348694 
	C561.070312,371.788239 559.704773,375.160431 557.707153,378.784241 
	C472.661377,379.003296 388.338806,378.987762 304.016266,379.045990 
	C300.272888,379.048553 296.390991,379.205322 292.809418,380.167786 
	C269.388641,386.461426 255.093277,403.917847 255.066528,428.049469 
	C254.924866,555.866394 254.985931,683.683472 255.042435,811.500549 
	C255.045197,817.745544 255.405563,824.017212 256.045898,830.230408 
	C258.299164,852.093689 277.614227,870.137451 299.467926,870.804077 
	C325.938843,871.611633 352.454224,870.962708 378.950348,870.944092 
	C376.237732,879.081055 373.622314,887.252258 370.779541,895.343506 
	C368.763824,901.080627 366.440979,906.709900 364.102295,912.722778 
	C343.616943,913.038818 323.279999,913.274414 302.953674,912.912720 
	C288.778412,912.660400 274.867981,910.056885 262.164276,903.533569 
	C247.268356,895.884644 235.014374,884.982483 226.252197,870.578674 
	C218.583252,857.971985 213.915787,844.493225 213.929214,829.389587 
	C214.050278,693.225586 214.068039,557.061340 213.895706,420.897430 
	C213.878281,407.140625 217.624954,394.649323 224.100754,382.945160 
	C233.424500,366.093750 247.007126,353.384338 264.502228,345.258575 
	C274.364655,340.677856 284.690979,337.792511 295.872528,337.903900 
	C324.692200,338.191040 353.516602,338.000000 383.000000,338.000000 
	C383.000000,336.290009 383.000000,334.703796 383.000000,333.117615 
	C383.000000,310.451385 383.287811,287.778931 382.799286,265.123230 
	C382.675903,259.403290 385.540588,255.928665 388.441803,252.143677 
	C398.913208,238.482468 409.547913,224.946350 420.141754,211.379272 
	C420.306152,211.168716 420.736023,211.165436 421.586334,210.887482 
	C423.066650,219.119370 424.507324,227.279602 426.006836,235.429031 
	C427.614685,244.167114 429.381317,252.877609 430.853699,261.637970 
	C431.295074,264.263885 431.622101,266.737885 428.114685,268.602631 
	C421.311829,272.219391 414.930511,276.646271 408.492126,280.914856 
	C407.366425,281.661194 406.116272,283.269592 406.105652,284.490143 
	C405.951965,302.133545 406.000366,319.778687 406.000366,337.711304 
	C428.405914,337.711304 450.596619,337.711304 472.970245,337.711304 
	C455.541809,292.028992 438.198853,246.570694 420.798523,200.962051 
	C407.915833,201.571732 395.741943,199.492325 383.960327,194.548660 
	C368.718994,188.153275 356.918365,177.815567 348.064636,164.099396 
	C340.740387,152.752716 336.050446,140.524612 336.951141,126.600914 
	C337.667358,115.528275 335.644073,104.316055 339.930023,93.453865 
	C347.011658,75.506485 358.219574,61.174274 375.031891,51.491417 
	C388.103607,43.962894 402.123779,40.358528 417.322296,40.896229 
	C425.406708,41.182232 431.726715,48.401058 432.126953,56.395008 
	C432.608490,66.013550 425.068329,72.745316 417.609833,73.053581 
	C398.063812,73.861443 382.388214,81.881531 373.252258,99.633659 
	C364.629028,116.389389 366.934082,136.844223 380.022278,151.533569 
	C390.569794,163.371399 403.628571,168.200241 419.514557,168.139908 
	C499.678589,167.835434 579.844360,168.000000 660.009521,168.000000 
	C661.836121,168.000000 663.662720,168.000000 667.117371,168.000000 
	C663.352051,173.070114 660.218262,177.289749 657.169495,181.394958 
	C663.459717,189.993652 664.795227,199.056412 660.032104,208.664398 
	C655.432922,217.941803 647.287598,221.468628 637.459473,221.973267 
	C636.332825,222.031113 635.036865,222.018890 634.075256,221.532928 
	C628.295776,218.612045 624.679260,220.354370 620.741516,225.515717 
	C606.835083,243.743393 592.297302,261.488586 578.122620,279.513977 
	C576.941162,281.016418 576.114807,283.239990 576.091492,285.140442 
	C575.923767,298.804291 576.006226,312.471252 576.067749,326.615417 
M512.502319,338.000000 
	C525.932922,338.000000 539.363464,338.000000 552.706299,338.000000 
	C552.706299,307.014465 552.706299,276.609100 552.706299,246.000000 
	C541.934021,246.000000 531.445068,246.265869 520.977844,245.903992 
	C514.300598,245.673157 509.129303,246.429916 504.515228,252.592102 
	C497.156281,262.420044 488.300568,271.122253 480.215027,280.419830 
	C479.510498,281.230011 479.386810,283.058380 479.730286,284.190186 
	C485.168152,302.109009 490.726654,319.991241 496.294037,338.000000 
	C501.526489,338.000000 506.515320,338.000000 512.502319,338.000000 
z"
      />
      <path
        fill="#e2e2e0
        "
        opacity="1.000000"
        stroke="none"
        d="
M661.513672,913.030701 
	C660.702026,911.636597 659.991821,910.197571 660.062561,908.797913 
	C660.637939,897.402344 661.342224,886.013123 662.067627,874.625793 
	C662.128540,873.669067 662.630493,872.740417 663.452393,871.397949 
	C678.880676,870.664612 693.858093,871.127869 708.664124,869.749573 
	C720.669006,868.632019 730.319458,862.013367 737.562073,852.029846 
	C744.597961,842.331360 746.314697,831.892944 746.274780,819.876465 
	C745.836670,688.093384 746.009399,556.308350 745.983887,424.523834 
	C745.981567,412.622009 741.204102,402.419067 733.289978,393.944916 
	C722.786011,382.697632 709.154541,378.746613 694.075195,379.079041 
	C693.717041,376.373932 692.925964,373.644440 693.076538,370.967957 
	C693.687683,360.100555 694.587891,349.249420 695.728149,338.137146 
	C707.355286,337.946320 718.568604,337.796265 729.461975,341.919983 
	C749.832947,349.631470 765.692383,362.562897 776.706116,381.320740 
	C783.762878,393.339294 787.879639,406.250671 788.041870,420.349792 
	C788.165588,431.102203 788.950623,441.850159 788.956604,452.600342 
	C789.024780,575.746765 789.107483,698.893433 788.899353,822.039551 
	C788.862549,843.816772 782.293945,863.610840 767.985291,880.637146 
	C754.070312,897.195007 736.801331,908.248108 715.419800,910.725281 
	C697.754700,912.771851 679.802246,912.338501 661.513672,913.030701 
z"
      />
      <path
        fill="#e2e2e0
        "
        opacity="1.000000"
        stroke="none"
        d="
M570.955444,870.938293 
	C570.022766,883.195435 569.083557,895.452026 568.165955,907.710266 
	C568.055115,909.191162 568.061157,910.680786 567.980835,912.608276 
	C530.959961,913.034180 493.971405,913.018066 456.520447,913.018799 
	C460.852966,899.101074 465.647858,885.166565 471.224396,871.111938 
	C504.989197,870.973877 537.972290,870.956055 570.955444,870.938293 
z"
      />
      <path
        fill="#e2e2e0
        "
        opacity="1.000000"
        stroke="none"
        d="
M652.040527,317.592712 
	C643.685181,317.991211 635.291382,318.022034 626.429321,317.998962 
	C617.539429,305.362457 609.230591,292.701904 600.623352,280.247620 
	C598.236633,276.794159 598.098877,274.030945 599.857666,270.205017 
	C603.731506,261.778198 606.966614,253.057770 611.032104,243.055511 
	C624.924988,268.206268 638.463562,292.715668 652.040527,317.592712 
z"
      />
      <path
        fill="#191919
        "
        opacity="1.000000"
        stroke="none"
        d="
M379.094452,870.595642 
	C352.454224,870.962708 325.938843,871.611633 299.467926,870.804077 
	C277.614227,870.137451 258.299164,852.093689 256.045898,830.230408 
	C255.405563,824.017212 255.045197,817.745544 255.042435,811.500549 
	C254.985931,683.683472 254.924866,555.866394 255.066528,428.049469 
	C255.093277,403.917847 269.388641,386.461426 292.809418,380.167786 
	C296.390991,379.205322 300.272888,379.048553 304.016266,379.045990 
	C388.338806,378.987762 472.661377,379.003296 557.445984,379.037598 
	C553.820801,390.304321 549.742615,401.541687 545.644653,412.771820 
	C533.944702,444.834351 522.221313,476.888275 510.535736,508.956055 
	C495.822754,549.331665 481.114288,589.708923 466.448303,630.101624 
	C452.812195,667.657837 439.259094,705.244202 425.624054,742.800781 
	C412.288116,779.533325 398.894806,816.244934 385.527039,852.965942 
	C383.430115,858.726074 381.334686,864.486755 379.094452,870.595642 
z"
      />
      <path
        fill="#191919
        "
        opacity="1.000000"
        stroke="none"
        d="
M693.863770,379.443848 
	C709.154541,378.746613 722.786011,382.697632 733.289978,393.944916 
	C741.204102,402.419067 745.981567,412.622009 745.983887,424.523834 
	C746.009399,556.308350 745.836670,688.093384 746.274780,819.876465 
	C746.314697,831.892944 744.597961,842.331360 737.562073,852.029846 
	C730.319458,862.013367 720.669006,868.632019 708.664124,869.749573 
	C693.858093,871.127869 678.880676,870.664612 663.514038,870.978760 
	C663.366516,869.881531 663.913635,868.812866 663.961426,867.722351 
	C664.359802,858.634827 664.538330,849.536438 665.028015,840.454529 
	C665.932861,823.671021 666.990540,806.895630 668.010132,790.118469 
	C668.649536,779.596985 669.356079,769.079651 669.995300,758.558167 
	C671.014648,741.780090 671.986511,724.999023 673.010803,708.221252 
	C673.642944,697.867249 674.358643,687.518433 674.994995,677.164673 
	C676.016113,660.551514 676.998474,643.936035 678.005249,627.321960 
	C678.663025,616.466553 679.338074,605.612183 680.000244,594.756958 
	C681.003418,578.311829 682.000366,561.866272 683.003601,545.421143 
	C683.665833,534.565979 684.337646,523.711487 684.999817,512.856323 
	C686.003174,496.408936 686.995911,479.960907 688.004578,463.513824 
	C688.660156,452.825104 689.340332,442.137909 690.001526,431.449524 
	C690.673035,420.594574 691.279419,409.735260 692.021606,398.885193 
	C692.457092,392.518951 693.101807,386.167023 693.863770,379.443848 
z"
      />
      <path
        fill="#191919
        "
        opacity="1.000000"
        stroke="none"
        d="
M552.030762,636.083496 
	C557.369873,620.382263 562.632874,605.070496 567.986816,589.790588 
	C576.612976,565.171570 585.260986,540.559937 594.054077,516.000366 
	C594.583740,514.520935 596.471619,513.527771 597.727539,512.308411 
	C598.317871,512.628357 598.908264,512.948364 599.498596,513.268372 
	C598.711182,522.413574 597.908020,531.557434 597.142456,540.704468 
	C596.730286,545.629578 596.330261,550.556763 596.015991,555.488831 
	C595.313660,566.510803 594.756836,577.542664 593.979797,588.559143 
	C592.718628,606.439575 591.357849,624.313232 589.982483,642.185364 
	C589.037659,654.462219 588.014771,666.733154 586.995056,679.004089 
	C585.512756,696.841125 584.001831,714.675720 582.482178,732.760315 
	C561.502502,732.760315 540.404663,732.760315 518.717285,732.760315 
	C529.865356,700.509216 540.930420,668.498230 552.030762,636.083496 
z"
      />
      <path
        fill="#191919
        "
        opacity="1.000000"
        stroke="none"
        d="
M570.975342,870.495117 
	C537.972290,870.956055 504.989197,870.973877 471.543365,870.938416 
	C474.162659,861.347900 477.193268,851.793701 480.343292,842.278992 
	C482.760498,834.977783 482.827454,834.999878 490.824768,834.999878 
	C516.624451,834.999817 542.424133,834.999878 568.223816,834.999878 
	C570.031311,834.999878 571.838867,834.999878 573.609131,834.999878 
	C572.719666,846.927734 571.857422,858.489868 570.975342,870.495117 
z"
      />
      <path
        fill="#191919"
        opacity="1.000000"
        stroke="none"
        d="
M512.003235,338.000000 
	C506.515320,338.000000 501.526489,338.000000 496.294037,338.000000 
	C490.726654,319.991241 485.168152,302.109009 479.730286,284.190186 
	C479.386810,283.058380 479.510498,281.230011 480.215027,280.419830 
	C488.300568,271.122253 497.156281,262.420044 504.515228,252.592102 
	C509.129303,246.429916 514.300598,245.673157 520.977844,245.903992 
	C531.445068,246.265869 541.934021,246.000000 552.706299,246.000000 
	C552.706299,276.609100 552.706299,307.014465 552.706299,338.000000 
	C539.363464,338.000000 525.932922,338.000000 512.003235,338.000000 
z"
      />
    </svg>
  );
};
